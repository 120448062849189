import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
// import MainLogo from "../../../assets/logos/IntroLogo.webp";
// import upArrow from "../../../assets/images/enterprise-img/abm/arrow.png";
import MainLogo from "../../../assets/logos/Dlogo white.svg"
// import MainLogoB from "../../../assets/logos/IntroLogo.webp"
import { useLocation } from "react-router-dom";
import "./navbar.css"
import { HashLink } from "react-router-hash-link";

function Navbar() {
  const [icon, setIcon] = useState(false);
  // const [isNav , setIsNav] = useState(false);
  const handleClick = () => {
    setIcon(!icon);
  };

  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `/`; 
    navigate(path);
    window.scrollTo(0,0)
  }

  const closeSideDrawer = () => {
    setIcon(false);
  };
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  
  return (
    <>
      <div className="container-fluid education-nav-bg about-us nav-bg fixed-top" >
        <div className="container">
          <nav className="navbar navigation-extension">
            <div className="nav-logo">
              <img className="img-fluid" src={MainLogo} onClick={routeChange} alt={"Datalogics India"} />
            </div>
            <div className="menu-icon" onClick={handleClick}>
              {icon ? (
                <FaTimes className="fa-times"></FaTimes>
              ) : (
                <FaBars className="fa-bars"></FaBars>
              )}
            </div>

            <div className={icon ? "nav-menu active" : "nav-menu"}>
              <ul>
                <li
                  className={splitLocation[1] === "/People" ? "active" : ""}
                >
                  <HashLink
                    to="/people#people"
                    className="nav-link text-white"
                    onClick={closeSideDrawer}
                  >
                    People
                  </HashLink>
                </li>
                <li
                  className={splitLocation[1] === "/service" ? "active" : ""}
                >
                  <HashLink
                    to="/service#Home"
                    className="nav-link text-white"
                    onClick={closeSideDrawer}
                  >
                    Service
                  </HashLink>
                </li>
                <li
                  className={splitLocation[1] === "/enterprise" ? "active" : ""}
                >
                  <HashLink
                    to="/enterprise#Home"
                    className="nav-link text-white"
                    onClick={closeSideDrawer}
                  >
                    Enterprise
                  </HashLink>
                </li>
                <li
                  className={splitLocation[1] === "/education" ? "active" : ""}
                >
                  <HashLink
                    to="/education#Home"
                    className="nav-link text-white"
                    onClick={closeSideDrawer}
                  >
                    Education
                  </HashLink>
                </li>
                <li
                  className={splitLocation[1] === "/vector" ? "active" : ""}
                >
                  {" "}
                  <HashLink
                    to="/vector#Home"
                    className="nav-link text-white"
                    onClick={closeSideDrawer}
                  >
                    {/* Vectorworks - BIM */}
                    For Creators
                  </HashLink>
                </li>
                <li
                  className={splitLocation[1] === "/vector" ? "active" : ""}
                >
                  {" "}
                  <HashLink
                    to="/contact-us#Home"
                    className="nav-link text-white"
                    onClick={closeSideDrawer}
                  >
                    Contact Us
                  </HashLink>
                </li>
              </ul>
            </div>
    
          </nav>
        </div>
      </div>
    </>
  );
}

export default Navbar;
