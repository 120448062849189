import React from 'react'
import Navbar from '../About-us/Navbar/navbar'
// import Footer from '../pages/vector/commonfooter/footer'
import Footer from '../../sections/Footer/Footer';
import ContactUsForm from '../SwitchToMac/ContactUsForm';

import PDFViewer from "../../sections/PDFViewer/PDFViewer";

import CanalysReportPDF from "../../assets/data/canalys_report.pdf"

function CanalysReport() {
  return (
    <>
    <Navbar />
    <PDFViewer doc={CanalysReportPDF} title="Canalys Report" filename="Canalys Report" />
    <ContactUsForm ctaMain="Talk to Our Apple Specialist"  ctaPara="Discover how Macs with Apple's M Series chips can transform your AI workflows and boost productivity."  />
    <Footer />
</>
  )
}

export default CanalysReport