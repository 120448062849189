import "./SkochReport.css"
import React from 'react'
import Navbar from '../About-us/Navbar/navbar';
// import Footer from '../pages/vector/commonfooter/footer';
import Footer from '../../sections/Footer/Footer';
import ContactUsForm from '../SwitchToMac/ContactUsForm';

import PDFViewer from "../../sections/PDFViewer/PDFViewer";



import SkochReportPDF from "../../assets/data/Skoch_report.pdf"

function SkochReport() {
    return (
        <>
             <Navbar />
             <PDFViewer doc={SkochReportPDF} title="Skoch Report" filename="Skoch Report" />
             <ContactUsForm ctaMain="Talk to Our Apple Specialist" ctaPara="Discover how a Mac fleet can be more cost-effective than Windows PCs in the long run." />
             <Footer />
        </>
      )
}

export default SkochReport









