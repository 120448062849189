import React from "react";
import MainLogo from "../../../../assets/logos/Dlogo white.svg";
import linkedin from "../../../../assets/logos/social-logo/linkedin-w.png";
import facebook from "../../../../assets/logos/social-logo/facebook-w.png";
import instagram from "../../../../assets/logos/social-logo/instagram-w.png";
import twitter from "../../../../assets/logos/social-logo/twitter-w.png";
import "./footer.css";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function Footer() {

  let year = new Date()

    return (
      <>
        <div className="container-fluid enterprise-footer background-black pt-5">
          <div className="row footer-menu">
            <div className="col-12 col-lg-4 text-center">
                <img className="pb-3" src={MainLogo} alt="place_icon" style={{maxWidth:"300px"}} /> 
                <p className="pl-5 ml-4 text-lg-start">At Datalogics India, our relentless commitment spans over Three and a half decades, dedicated to serving our customers with unparalleled excellence. Our pursuit to win the hearts of new clients and elevate the satisfaction of existing ones remains unyielding.</p>
               <iframe className="ml-4 text-lg-start newsletter-subscribe-container" title="Newsletter Subscribe" width="540" height="525" src="https://3acee790.sibforms.com/serve/MUIFAD7XS-XXH0Pt8iK3zMoS7wGfIuNPCpnStdcGVdN-cxObpcBl1ScMQYDRpYBB2AVgEE-WD-BNRzV2x2WwpLu0vEmxrGYS1f5mWAaIft_UW554sSc50kH5I61VLG8LzCo0-tqFsKqbINigYLr_M3yLonUf1qIY_awYDfh1tFx1mS24lCYG1CG4ChQqiDNmHMeq32OJ48byFsXO" scrolling="auto"></iframe>
            </div>
            <div className="col-12 col-lg-8 d-flex flex-column flex-md-row flex-lg-row justify-content-center text-center text-lg-start justify-content-lg-evenly">
                {/* People */}
                <div className="p-2">
                  <HashLink className="nav-link" to="/" onClick={() => window.scrollTo(0, 0)} ><p className="font-bold">About Us</p></HashLink>
                  <HashLink className="nav-link" to="/contact-us" onClick={() => window.scrollTo(0, 0)} ><p className="font-bold">Contact Us</p></HashLink>
                  <HashLink className="nav-link" to="/people" onClick={() => window.scrollTo(0, 0)} ><p className="font-bold">People</p></HashLink>
                  <HashLink className="nav-link" to="/privacy-policy" onClick={() => window.scrollTo(0, 0)} ><p className="font-bold">Privacy Policy</p></HashLink>
                  <HashLink className="nav-link" to="/blog" onClick={() => window.scrollTo(0, 0)} ><p className="font-bold">Blog</p></HashLink>
                </div>

                {/* Service */}
                <div className="p-2">
                  <HashLink className="nav-link" to="/service" onClick={() => window.scrollTo(0, 0)} ><p className="font-bold">Service</p></HashLink>
                  <HashLink className="nav-link" to="/service/enterprise" onClick={() => window.scrollTo(0, 0)} ><p className="font-normal">Enterprise</p></HashLink>
                  <HashLink className="nav-link" to="/service/repairs" onClick={() => window.scrollTo(0, 0)} ><p className="font-normal">Repairs</p></HashLink>
                  <HashLink className="nav-link" to="/service/logistics" onClick={() => window.scrollTo(0, 0)} ><p className="font-normal">Logistics</p></HashLink>
                  <HashLink className="nav-link" to="/service/dcare" onClick={() => window.scrollTo(0, 0)} ><p className="font-normal">DCare</p></HashLink>
                  <HashLink className="nav-link" to="/service/dcare#DCareCoverage"><p className="font-normal">DCare Coverage</p></HashLink>
                  <HashLink className="nav-link" to="/service/lcm" onClick={() => window.scrollTo(0, 0)}><p className="font-normal">LCM</p></HashLink>
                </div>

                {/* Enterprise */}
                <div className="p-2">
                  <HashLink className="nav-link" to="/enterprise" onClick={() => window.scrollTo(0, 0)} ><p className="font-bold">Enterprise</p></HashLink>
                  <HashLink className="nav-link" to="/enterprise/start-ups" onClick={() => window.scrollTo(0, 0)} ><p className="font-normal">Start-Ups & SME</p></HashLink>
                  <HashLink className="nav-link" to="/enterprise/mid-market" onClick={() => window.scrollTo(0, 0)} ><p className="font-normal">Mid-Market</p></HashLink>
                  <HashLink className="nav-link" to="/enterprise/large-enterprise" onClick={() => window.scrollTo(0, 0)} ><p className="font-normal">Large Enterprises</p></HashLink>
                  <HashLink className="nav-link" to="/enterprise/developers" onClick={() => window.scrollTo(0, 0)} ><p className="font-normal">Developers</p></HashLink>
                  <HashLink className="nav-link" to="/enterprise/abm" onClick={() => window.scrollTo(0, 0)} ><p className="font-normal">ABM</p></HashLink>
                  <HashLink className="nav-link" to="/enterprise/switch-to-mac" onClick={() => window.scrollTo(0, 0)} ><p className="font-normal">Switch to Mac</p></HashLink>
                </div>

                {/* Education */}
                <div className="p-2">
                  <HashLink className="nav-link" to="/education" onClick={() => window.scrollTo(0, 0)} ><p className="font-bold">Education</p></HashLink>
                  <HashLink className="nav-link" to="/education/K12" onClick={() => window.scrollTo(0, 0)} ><p className="font-normal">K12</p></HashLink>
                  <HashLink className="nav-link" to="/education/Hi-Education" onClick={() => window.scrollTo(0, 0)} ><p className="font-normal">Hi-Education</p></HashLink>
                  <HashLink className="nav-link" to="/education/teachers" onClick={() => window.scrollTo(0, 0)} ><p className="font-normal">Teachers</p></HashLink>
                  <HashLink className="nav-link" to="/education/Parents" onClick={() => window.scrollTo(0, 0)} ><p className="font-normal">Parents</p></HashLink>
                  <HashLink className="nav-link" to="/education/Leaders" onClick={() => window.scrollTo(0, 0)} ><p className="font-normal">Leaders</p></HashLink>
                  <HashLink className="nav-link" to="/education/IT" onClick={() => window.scrollTo(0, 0)} ><p className="font-normal">IT</p></HashLink>
                  <HashLink className="nav-link" to="/education/CaseStudy" onClick={() => window.scrollTo(0, 0)} ><p className="font-normal">Case Study</p></HashLink>
                  <HashLink className="nav-link" to="/education/EduService" onClick={() => window.scrollTo(0, 0)} ><p className="font-normal">Service</p></HashLink>
                  <HashLink className="nav-link" to="/education/DEP" onClick={() => window.scrollTo(0, 0)} ><p className="font-normal">DEP</p></HashLink>
                  <HashLink className="nav-link" to="/education/DTrain" onClick={() => window.scrollTo(0, 0)} ><p className="font-normal">DTrain</p></HashLink>
                  <HashLink className="nav-link" to="/education/DCareSchool" onClick={() => window.scrollTo(0, 0)} ><p className="font-normal">DCare School</p></HashLink>
                </div>

                {/* Vectorworks */}
                <div className="p-2">
                  <HashLink className="nav-link" to="/vector" onClick={() => window.scrollTo(0, 0)} ><p className="font-bold">Vectorworks</p></HashLink>
                  <HashLink className="nav-link" to="/vector/Buildings" onClick={() => window.scrollTo(0, 0)} ><p className="font-normal">Buildings</p></HashLink> 
                  <HashLink className="nav-link" to="/vector/Landscap" onClick={() => window.scrollTo(0, 0)} ><p className="font-normal">Landscapes</p></HashLink>
                  <HashLink className="nav-link" to="/vector/Entertainments" onClick={() => window.scrollTo(0, 0)} ><p className="font-normal">Entertainment</p></HashLink>
                  <HashLink className="nav-link" to="/vector#AllProducts" ><p className="font-normal">All Products</p></HashLink>
                  <HashLink className="nav-link" to="/vector/Training"  onClick={() => window.scrollTo(0, 0)}><p className="font-normal">Training</p></HashLink>
                  <HashLink className="nav-link" to="/vector#Enquiry" onClick={() => window.scrollTo(0, 0)} ><p className="font-normal">Enquiry</p></HashLink>
                </div>
            </div>
          </div>

          {/* Social Icons */}
          <p className="d-flex social-icons">
              <Link className="nav-link" to="https://www.linkedin.com/company/datalogics-india/mycompany/" target="_blank" rel="noreferrer"><img src={linkedin} alt="LinkedIn" title="LinkedIn" /></Link>&nbsp; &nbsp; 
              <Link className="nav-link" to="https://www.facebook.com/people/Datalogics-India-Private-Limited/100086091872602/" target="_blank" rel="noreferrer"><img src={facebook} alt="Facebook" title="Facebook" /></Link>&nbsp; &nbsp; 
              <Link className="nav-link" to="https://www.instagram.com/datalogics_india_pvt_ltd/" target="_blank" rel="noreferrer"><img src={instagram} alt="Instagram" title="Instagram" /></Link>&nbsp; &nbsp; 
              <Link className="nav-link" to="https://x.com/Datalogics_IN" target="_blank" rel="noreferrer"><img src={twitter} alt="Twitter" title="Twitter" /></Link>
          </p>

          <div className="copyright text-center p-3">
            <p>Copyright © {year.getFullYear()} Datalogics India Pvt. Ltd. All rights reserved.</p>
          </div>
        </div>
      </>
    );
  }
  export default Footer;
  